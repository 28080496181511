<template>
	<div style="padding: 10px;">
			<div class="tableModule" ref="tableContainer">
				<div class="title">
					<div class="title-left">
						<i class="el-icon-s-operation"></i>
						<span>品牌列表</span>
					</div>
					<div class="title-right"><el-button type="primary" size="medium" style="margin-right: 8px;" @click="insert()">新增品牌</el-button></div>
				</div>
				<el-table
					ref="usertable"
					:data="tableData"
					border
					:header-cell-style="{ 'background-color': '#f9fafc' }"
					:cell-style="{ padding: 0 }"
					style="margin-bottom: 20px;"
					v-loading="isLoading"
				>
					<el-table-column align="center" label="序号" width="180">
						<template slot-scope="scope">
							<div>{{ scope.$index + 1 }}</div>
						</template>
					</el-table-column>
					<el-table-column prop="name"  label="品牌设置" ></el-table-column>
					<el-table-column prop="date" align="center" label="图标" width="320">
						<template slot-scope="scope">
							<img :src="uploadUrl+scope.row.image" alt="" style="height: 50px;margin-top: 10px;">
						</template>
					</el-table-column>
					<el-table-column prop="sort" align="center" label="排序" width="320"></el-table-column>
					<el-table-column  align="center" label="操作" width="180">
						<template slot-scope="scope">
							<el-button type="text" @click="edit(scope.row)">
								编辑
							</el-button>
							<el-button type="text" @click="deletes(scope.row)">
								删除
							</el-button>
							
						</template>
					</el-table-column>
				</el-table>
			</div>
		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
	</div>
</template>

<script>
	import edit from "./edit.vue"
	import configApi from '@config/configApi';
export default {
	components: {
		edit
	},
	data() {
		return {
			tableData: [],
			isLoading: false,
			showEditDialog:false,
			editObj:{},
			uploadUrl: configApi.photoURL,
		};
	},
	methods: {
		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		},
		//展示数据
		list() {
			this.$get('/shop-api/brand/mgmt/list')
				.then(res => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(err => {
					//请求失败，关闭loading
					this.isLoading = false;
				});
		},
		//打开新增弹窗
		insert() {
			this.editObj = {};
			this.editObj["type"] = 2;
			this.showEditDialog = true;
		},
		//打开编辑弹窗
		edit(row) {
			this.editObj = {};
			this.editObj["id"] = row.id;
			this.editObj["type"] = 1;
			this.showEditDialog = true;
		},
		//删除
		deletes(row){
			this.$post('/shop-api/brand/mgmt/delete?id='+row.id).then(res=>{
				if (res.code != 1000) {
					this.$message.error(res.msg);
					return;
				}
				this.$message.success(res.msg);
				this.list();
			})
		}
	},
	mounted() {
		this.list();
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
