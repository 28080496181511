<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="品牌LOGO：">
							<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload" :http-request="Upload">
								<el-image v-if="formData.image" :src="uploadUrl + formData.image" fit="fill" class="avatar"></el-image>
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="品牌名称：" prop="name"><el-input v-model="formData.name"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="品牌排序：" prop="sort"><el-input v-model="formData.sort"></el-input></el-form-item>
					</el-col>
				</el-row>
				
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: '',
			loading: false,
			formData: {},
			rules: {
				name: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
				sort: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
			},
			uploadUrl: configApi.photoURL,
			newFile: new FormData()
		};
	},
	methods: {
		//上传前校验
		BeforeUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 10;
			const fileType = file.name.substring(file.name.lastIndexOf('.'));
			if (fileType != '.jpg' && fileType != '.JPG' && fileType != '.PNG' && fileType != '.png') {
				this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
			} else if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10MB!');
			} else {
				this.currentFileName = file.name;
				if (file) {
					if(this.newFile.has("file")){
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
			}
			return false;
		},
		//手动上传
		Upload(param) {
			this.loading = true;
			this.$upload(this.newFile)
				.then(res => {
					if (res.code == 1000) {
						this.formData.image = res.data;
						this.loading = false;
						this.newFile = new FormData();
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(error => {
					this.$message.error(error);
				});
		},
		//打开弹窗
		open() {
			this.formData = {};
			this.get();
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			});
		},
		//获取数据
		get() {
			if (this.dialogObj.type == 0 || this.dialogObj.type == 1) {
				this.title = '编辑';
				this.$get('/shop-api/brand/mgmt/get?id=' + this.dialogObj.id).then(res => {
					if (res.code == 1000) {
						this.formData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			} else if (this.dialogObj.type == 2) {
				this.title = '添加';
			}
		},
		//提交表单
		submit() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.loading = true;
					if (this.dialogObj.type == 1) {
						this.$postJson('/shop-api/brand/mgmt/update', this.formData).then(res => {
							this.loading = false;
							if (res.code != 1000) {
								this.$message.error(res.msg);
								return;
							}
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						});
					} else if (this.dialogObj.type == 2) {
						this.$postJson('/shop-api/brand/mgmt/save', this.formData).then(res => {
							this.loading = false;
							if (res.code != 1000) {
								this.$message.error(res.msg);
								return;
							}
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						});
					}
				} else {
					return false;
				}
			});
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep.cascader {
		display: block;
	}
}

::v-deep.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
::v-deep.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 78px;
	height: 78px;
	line-height: 78px;
	text-align: center;
}
.avatar {
	width: 78px;
	height: 78px;
	display: block;
}
</style>
